.manage-user-withdrawls-wrapper {
  margin: 30px 30px 30px 0;

  h5 {
    font-size: 22px;
    font-weight: 400;
    color: #474747;
  }

  .btn-primary {
    border-color: #258292;
  }

  .manage-user-withdrawls {
    margin: 25px 0px 0px 0;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    padding: 30px 45px;
    border-radius: 3px;

    h5 {
      font-size: 16px;
      font-weight: 400;
      color: #474747;
      padding-bottom: 20px;
    }

    th,
    td {
      font-size: 14px;
      color: #474747;
    }

    .results {
      margin-top: 48px;
    }

    .icon {
      color: #258292;
    }

    img {
      width: 40px;
      border-radius: 50%;
    }

    a i {
      text-decoration: none;
      color: #474747;
      font-size: 16px;
      padding-right: 12px;
    }

    .add-new-user-btn {
      background-color: #258292;
      color: #fff;
      padding: 7px 30px;
      font-size: 14px;
    }

    .social {
      padding-top: 10px;

      .pretty {
        float: right;
      }
    }
  }
}

.active,
.suspended {
  color: #fff;
  background-color: green;
  padding: 4px 10px 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

.suspended {
  background-color: tomato;
}
