* {
  margin: 0;
  padding: 0;
  font-family: "Helvetica";
}

.form-control {
  border-radius: 0;
  outline: none;
  padding-left: 35px;
  font-size: 14px;

  &:focus {
    border-color: #37a1b3;
  }
}

.btn {
  border-radius: 0;
  outline: none;
}

h6 {
  font-size: 15px;
  font-weight: 400;
  color: #474747;
}

h5 {
  font-size: 17px;
  font-weight: 400;
  color: #474747;
}

h3 {
  font-size: 25px;
  font-weight: 400;
  color: #474747;
}

/* ----------------------- Input Icons -------------------------- */

.input-icons i {
  position: absolute;
}
.icon {
  padding: 10px;
}

/*---------------------------- scroll bar ------------------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #37a1b3;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #37a1b3;
}
::-webkit-scrollbar-thumb:active {
  background: #37a1b3;
}
::-webkit-scrollbar-track {
  background: #b5b5b5;
  border: 0px none #b5b5b5;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
